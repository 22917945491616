'use strict';

const $document = $(document);
const $body = $('body');

collapseSearch();

function collapseSearch() {

    var $collapseTrigger = $('.js-search-trigger');
    var $form = $('.js-search-form');

    // register clicks and toggle classes
    $collapseTrigger.on('click', function (e) {

        e.preventDefault();

        var $container = $(this).closest('.js-search-container');
        var $inputEl = $container.find('.js-search-input');

        if ($form.hasClass('active')) {
            $form.removeClass('active');
            $body.removeClass('search-active');
            $('.js-search-container').removeClass('active');
        } else {
            $inputEl.addClass('focus');
            $form.addClass('active');
            $body.addClass('search-active');
            $('.js-search-container').addClass('active');
            var labelId = $form.find('.search__input').attr('id');
            $('#' + labelId).focus();
        }
    });

    $document.on('click', '.search__close, .flyout__overlay', function (event) {
        $body.removeClass('search-active');
        $form.removeClass('active');
        $('.js-search-container').removeClass('active');
    });

    $document.on('click', '#main', function (event) {
        $body.removeClass('search-active');
        $form.removeClass('active');
        $('.js-search-container').removeClass('active');
    });

    $document.keyup( function (e) {
        if (e.keyCode === 27) {
            if ($body.hasClass('search-active')) {
                $form.removeClass('active');
                $body.removeClass('search-active');
                $('.js-search-container').removeClass('active');
            }
        }
    });
}
