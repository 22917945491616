'use strict';

import './components/common';
import './components/form';
import './components/flyout';
import './components/responsiveBackground';
import './components/collapse';
import './components/search';

//  Icon font generation - do not remove
import '../icons/iconfont.font';
