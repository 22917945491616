window.Parsley.addCatalog('fr', {
    defaultMessage:     'Cette valeur semble incorrecte.',
    type: {
        email:          'Il ne semble pas s\'agir d\'une adresse électronique valide.',
        url:            'Il ne semble pas s\'agir d\'une URL valide.',
        urlstrict:      'Il ne s\'agit pas d\'une URL valide.',
        number:         'Cette valeur doit être numérique.',
        integer:        'Cette valeur doit être un nombre.',
        digits:         'Cette valeur doit être numérique.',
        dateIso:        'Cette valeur doit être une date au format suivant : (AAAA-MM-JJ).',
        alphanum:       'Cette valeur doit être alphanumérique.',
        phone:          'Cette valeur doit être un numéro de téléphone valide.'
    },
    notnull:            'Cette valeur ne doit pas être vide.',
    notblank:           'Cette valeur ne doit pas être vide.',
    required:           'Ce champ est obligatoire',
    regexp:             'Cette valeur semble incorrecte.',
    pattern:            'Cette valeur semble incorrecte.',
    min:                'Cette valeur ne doit pas être inférieure à %s.',
    max:                'Cette valeur ne doit pas dépasser %s.',
    range:              'Cette valeur doit être comprise entre %s et %s.',
    minlength:          'Ce texte est trop court. Il devrait comporter au moins %s caractères.',
    maxlength:          'Cette valeur est trop longue. Elle doit comporter au maximum %s caractères.',
    length:             'Cette valeur doit être comprise entre %s et %s caractères.',
    mincheck:           'Vous devez sélectionner au moins %s options.',
    maxcheck:           'Vous devez sélectionner %s ou moins d\'options.',
    check:              'Vous devez sélectionner des options entre %s et %s',
    rangecheck:         'Vous devez choisir entre les options %s et %s.',
    rangelength:        'Cette valeur doit être comprise entre %s et %s caractères.',
    equalto:            'Ces valeurs doivent être identiques.'
}, true);
