// This is made for the hero component

'use strict';

$('.js-bg-image').on('load', function (e) {

    const $hero = $(this).closest('.js-bg-container');
    const imgSrc = this.currentSrc || this.src;

    $hero.css('background-image', 'url(' + imgSrc + ')');

    setTimeout(function () {
        $hero.addClass('is-loaded');
    }, 250);

}).trigger('load');